<template>
  <v-app :style="colorVapp">
    <onebox_toolbar></onebox_toolbar>
    <v-content>
      <v-card class="elevation-0">
        <!-- <v-overlay :value="processloader" absolute :color="color.BG" dark>
          <v-progress-circular indeterminate size="64" :color="color.theme"></v-progress-circular>
          <br />
          <br />
          <span :style="colorProgress">&nbsp; &nbsp; loading</span>
        </v-overlay> -->
        <v-list nav :color="color.BG" class="px-4" v-if="resolutionScreen < 500">
          <v-card rounded="xl" class="elevation-0 d-flex align-center" height="40">
            <v-list-item class="text-left">
              <v-list-item-avatar class="text-center ma-0">
                <v-avatar :color="color.theme" size="25">
                  <v-icon dark size="20" @click="fn_backtomainpage()">mdi-keyboard-backspace</v-icon>
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="pl-3 pt-1 pb-1">
                  <span :style="headerPage">{{ $t("admintoolbar.paymentlist") }}</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-list>
        <v-list nav :color="color.BG" v-else>
          <v-list-item class="text-left">
            <v-list-item-avatar class="text-center">
              <v-avatar :color="color.theme" size="35">
                <v-icon dark size="20" @click="fn_backtomainpage()"
                  >mdi-chevron-left</v-icon
                >
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <span :style="headerPage">{{
                  $t("admintoolbar.paymentlist")
                }}</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-card
          class="elevation-0"
          :color="color.BG"
          id="thiscontainer_packagpurchasehistory"
        >
          <v-card-text class="pa-0">
            <v-container fluid>
              <v-layout row wrap justify-center fill-height>
                <!-- <v-flex lg12 xs12 class="ma-4">
              <v-expansion-panels v-model="panel" multiple outlined>
                <v-expansion-panel class="pa-1">
                  <v-expansion-panel-header>
                    <span :style="headerPage">ค้นหาเอกสาร</span>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="pa-1">
                    <v-form>
                      <v-layout row wrap fill-height justify-center>
                        <v-flex xs12 sm12 md5 lg5 class="pa-1">
                          <v-container fluid>
                            <v-layout row wrap justify-center>
                              <v-flex xs12 sm12 md12 lg12>
                                <v-text-field
                                  clearable
                                  outlined
                                  dense
                                  class="pa-1 pt-0 pb-0"
                                  v-model="namecustomer"
                                  label="ชื่อลูกค้า"
                                  prepend-icon="insert_drive_file"
                                ></v-text-field>
                              </v-flex>
                            </v-layout>
                            <v-layout row wrap justify-center>
                              <v-flex xs12 sm12 md6 lg6>
                                <v-dialog
                                  ref="dialogfromdatepay"
                                  v-model="modalFromdatepay"
                                  :return-value.sync="fromdatepay"
                                  persistent
                                  full-width
                                  width="290px"
                                  color="#263238"
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-text-field
                                      clearable
                                      label="วันที่ชำระ"
                                      v-model="fromdatepay"
                                      @click:prepend="modalFromdatepay = true"
                                      prepend-icon="event"
                                      v-on="on"
                                      readonly
                                      outlined
                                      dense
                                      @click:clear="todatepay = ''"
                                      class="my-hint-style pa-1 pt-0 pb-0"
                                      color="#263238"
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker v-model="fromdatepay" scrollable @input="changefromdatepay()">
                                    <v-spacer></v-spacer>
                                    <v-btn dark color="error" @click.stop="modalFromdatepay = false">ปิด</v-btn>
                                  </v-date-picker>
                                </v-dialog>
                              </v-flex>
                              <v-flex xs12 sm12 md6 lg6>
                                <v-dialog
                                  ref="dialogtodatepay"
                                  v-model="modaltodatepay"
                                  :return-value.sync="todatepay"
                                  persistent
                                  full-width
                                  width="290px"
                                  color="#263238"
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-text-field
                                      clearable
                                      readonly
                                      color="#263238"
                                      label="ถึง"
                                      v-model="todatepay"
                                      prepend-icon="event"
                                      @click:prepend="modaltodatepay = true"
                                      v-on="on"
                                      outlined
                                      dense
                                      class="my-hint-style pa-1 pt-0 pb-0"
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="todatepay"
                                    scrollable
                                    @input="$refs.dialogtodatepay.save(todatepay)"
                                    :min="fromdatepay"
                                    color="#263238"
                                  >
                                    <v-spacer></v-spacer>
                                    <v-btn dark color="error" @click.stop="modaltodatepay = false">ปิด</v-btn>
                                  </v-date-picker>
                                </v-dialog>
                              </v-flex>
                            </v-layout>
                          </v-container>
                        </v-flex>

                        <v-flex xs12 sm12 md1 lg1 class="text-center">
                          <v-divider vertical></v-divider>
                        </v-flex>

                        <v-flex xs12 sm12 md5 lg5 class="pa-1 text-center">
                          <v-container fluid>
                            <v-layout row wrap justify-center>
                              <v-flex xs12 sm12 md12 lg12>
                                <v-text-field
                                  outlined
                                  dense
                                  class="pa-1 pt-0 pb-0"
                                  v-model="orderid"
                                  label="Order ID"
                                  prepend-icon="format_list_numbered"
                                ></v-text-field>
                              </v-flex>
                            </v-layout>
                            <v-layout row wrap justify-center>
                              <v-flex xs12 sm12 md12 lg12>
                                <v-select
                                  clearable
                                  outlined
                                  dense
                                  class="pa-1 pt-0 pb-0"
                                  v-model="statuspayment"
                                  :items="liststatuspayment"
                                  item-value="statuspayment_value"
                                  item-text="statuspayment_type"
                                  label="สถานะการชำระเงิน"
                                  prepend-icon="reorder"
                                  multiple
                                >
                                </v-select>
                              </v-flex>
                            </v-layout>
                          </v-container>
                        </v-flex>

                        <v-flex xs12 sm12 md5 lg5 class="text-center">
                          <v-btn
                            class="ma-1 white--text"
                            color="#263238"
                            :disabled="loaddataprogress"
                            :loading="loaddataprogress"
                            @click="fn_searchlistpayment()"
                          >
                            &nbsp;
                            ค้นหา
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </v-form>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
                  </v-flex> -->
                <v-flex lg12 xs12 class="ma-4">
                  <!-- <div v-if="resolutionScreen <= 500">
                    <v-list two-line class="mb-6 pb-6 elevation-0" v-if="rootfile.length !== 0">
                      <v-list-item v-for="item in rootfile" :key="item.title" style="cursor: pointer;">
                        <v-list-item-avatar>
                          <v-badge color="orange" icon="link" overlap v-if="item.file_status_sharelink === 'Y'">
                            <v-icon x-large :color="item.file_icon[1]" style="font-weight: 100">{{ item.file_icon[0] }}</v-icon>
                          </v-badge>
                          <div v-else>
                            <v-icon x-large :color="item.file_icon[1]" style="font-weight: 100">{{ item.file_icon[0] }}</v-icon>
                          </div>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title v-text="item.file_name"></v-list-item-title>
                          <v-list-item-subtitle v-text="calculatesize(item.file_size)"></v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-action>
                          <v-btn class="elevation-0" fab small @click="test($event, item)" outlined>
                            <v-icon>more_vert</v-icon>
                          </v-btn>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>
                    <v-list v-else class="elevation-0">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title class="text-center" v-text="$t('tablefile.empty')"></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </div> -->
                  <div v-if="resolutionScreen >= 500">
                    <v-data-table
                      v-model="filemultipledelete"
                      :headers="headers"
                      :items="rootfile"
                      :no-data-text="$t('tablefile.empty_resend')"
                      :single-select="singleSelect"
                      :search="search"
                      item-key="data_id"
                      item-selected
                      :show-select="statusmutipledelete"
                      class="elevation-0"
                      :items-per-page="rootfile.length"
                      :hide-default-footer="true"
                    >
                      <!-- <template v-slot:[`header.file_type`]="{ header }">
                        <span :style="headerTable">{{ header.text }}</span>
                      </template> -->
                      <template v-slot:[`header.package_name`]="{ header }">
                        <span
                          class="pointer"
                          @click="
                            (multiSort = !multiSort),
                              (sortby = 'name'),
                              (clickSort = true),
                              sortFile(rootfile)
                          "
                          :style="headerTable"
                          >{{ $t(header.text) }}</span
                        >
                      </template>
                      <template
                        v-slot:[`header.package_name_addon`]="{ header }"
                      >
                        <span
                          class="pointer"
                          @click="
                            (multiSort = !multiSort),
                              (sortby = 'name'),
                              (clickSort = true),
                              sortFile(rootfile)
                          "
                          :style="headerTable"
                          >{{ $t(header.text) }}</span
                        >
                      </template>
                      <template v-slot:[`header.amount`]="{ header }">
                        <span
                          class="pointer"
                          @click="
                            (multiSort = !multiSort),
                              (sortby = 'owner'),
                              (clickSort = true),
                              sortFile(rootfile)
                          "
                          :style="headerTable"
                          >{{ $t(header.text) }}</span
                        >
                      </template>
                      <template v-slot:[`header.pay_type`]="{ header }">
                        <span :style="headerTable">{{ $t(header.text) }}</span>
                      </template>
                      <template v-slot:[`header.paid_dtm`]="{ header }">
                        <span :style="headerTable">{{ $t(header.text) }}</span>
                      </template>
                      <template v-slot:[`header.status_payment`]="{ header }">
                        <span :style="headerTable">{{ $t(header.text) }}</span>
                      </template>
                      <template v-slot:[`header.file_status`]="{ header }">
                        <span :style="headerTable">{{ $t(header.text) }}</span>
                      </template>
                      <!-- <template v-slot:[`header.order_id`]="{ header }">
                        <span :style="headerTable">{{ $t(header.text) }}</span>
                      </template> -->
                      <template v-slot:[`header.pay`]="{ header }">
                        <span :style="headerTable">{{ $t(header.text) }}</span>
                      </template>

                      <template v-slot:item="props">
                        <tr
                          @contextmenu="test($event, props.item)"
                          style="cursor: pointer"
                        >
                          <!-- <td class="text-center" v-show="statusmutipledelete">
                            <v-checkbox v-model="filemultipledelete" :value="props.item" />
                          </td> -->
                          <!-- <td width="20%">{{ props.item.package_name !== '' ?  props.item.package_name + ' | ' + props.item.package_storage_main : props.item.package_name_addon + ' (' + props.item.quantity_addon + ' unit'+')' }}</td> -->
                          <td width="20%">
                            {{
                              props.item.package_name !== ""
                                ? props.item.package_name +
                                  " | " +
                                  props.item.package_storage_main
                                : "-"
                            }}
                          </td>
                          <td width="20%">
                            {{
                              props.item.package_name_addon !== ""
                                ? props.item.package_name_addon +
                                  " (" +
                                  props.item.quantity_addon +
                                  " unit)"
                                : "-"
                            }}
                          </td>
                          <td width="15%">
                            {{ numberWithCommas(props.item.amount) }}
                          </td>
                          <td width="15%">
                            {{ formatdatetime(props.item.paid_dtm) }}
                          </td>
                          <td width="15%">
                            {{ formatdatetime(props.item.paid_dtm) }}
                          </td>
                          <td width="15%" class="text-center">
                            <v-chip
                              :color="
                                props.item.status_payment ===
                                  'wait_nextmonth' ||
                                props.item.status_payment === 'wait'
                                  ? 'amber'
                                  : props.item.status_payment === 'Success' ||
                                    props.item.status_payment ===
                                      'success_nexmount'
                                  ? 'green'
                                  : 'red'
                              "
                              dark
                              >{{
                                props.item.status_payment === "wait_nextmonth"
                                  ? "waiting to pay"
                                  : props.item.status_payment ===
                                      "Incomplete" ||
                                    props.item.status_payment === "Incomplete"
                                  ? " Fail"
                                  : props.item.status_payment ===
                                    "success_nexmount"
                                  ? "Success"
                                  : props.item.status_payment
                              }}</v-chip
                            >
                          </td>
                          <td width="10%" class="text-center">
                            <v-btn
                              class="elevation-0"
                              :disabled="props.item.status_payment === 'Cancel'"
                              @click="fn_download(props.item)"
                              :color="color.theme"
                            >
                              <v-icon color="white">mdi-download</v-icon>
                            </v-btn>
                          </td>
                          <!-- <td width="10%" class="text-center">
                            <v-btn class="elevation-0" @click="fn_download(props.item)" :color="color.theme" :disabled="props.item.status_payment  !== 'Success'">
                              <v-icon color="white">mdi-download</v-icon>
                            </v-btn>
                          </td> -->
                          <td width="10%" class="text-center">
                            <v-btn
                              class="elevation-0 white--text"
                              :disabled="
                                props.item.status_payment === 'Success' ||
                                  props.item.status_payment === 'Cancel' ||
                                  props.item.status_payment ===
                                    'success_nexmount'
                              "
                              @click="fn_payment(props.item)"
                              :color="color.theme"
                            >
                              {{ $t("paymentlist.pay") }}
                            </v-btn>
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                  </div>
                  <div v-else>
                    <v-list
                      two-line
                      class="elevation-0"
                      v-if="rootfile.length !== 0"
                      id="borderradius"
                    >
                      <template v-for="(item, i) in rootfile">
                        <v-list-item :key="i.title">
                          <img width="30px" class="mr-4" src="@/assets/package_history.png" />
                          <v-list-item-content>
                            <v-list-item-title>
                              {{
                                item.package_name !== ""
                                  ? item.package_name +
                                    " | " +
                                    item.package_storage_main
                                  : "-"
                              }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              {{
                                item.package_name_addon !== ""
                                  ? item.package_name_addon +
                                    " (" +
                                    item.quantity_addon +
                                    " unit)"
                                  : "-"
                              }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <!-- <v-list-item-content v-if="resolutionScreen >= 400">
                              <v-list-item-subtitle>
                                {{ formatdatetime(item.paid_dtm) }}
                              </v-list-item-subtitle>
                              <v-list-item-subtitle>
                                {{ item.pay_type || "-" }}
                              </v-list-item-subtitle>
                            </v-list-item-content> -->
                          <v-list-item-content v-if="resolutionScreen >= 400">
                            <v-list-item-subtitle class="text-center">
                              <v-btn
                                class="elevation-0 white--text"
                                :disabled="
                                  item.status_payment === 'Success' ||
                                    item.status_payment === 'Cancel' ||
                                    item.status_payment === 'success_nexmount'
                                "
                                @click="fn_payment(item)"
                                :color="color.theme"
                              >
                                {{ $t("paymentlist.pay") }}
                              </v-btn>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-content v-if="resolutionScreen >= 400">
                            <v-list-item-subtitle class="text-center">
                              <v-btn
                                class="elevation-0"
                                :disabled="
                                  item.status_payment !== 'Success' ||
                                    item.file_id === ''
                                "
                                @click="fn_download_taxinvoice(item)"
                                :color="color.theme"
                              >
                                <v-icon color="white">mdi-download</v-icon>
                              </v-btn>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-btn
                              class="elevation-0"
                              fab
                              text
                              small
                              @click.stop="
                                (opendetailpackegehistory = true),
                                  (filepackage = item)
                              "
                              :color="color.theme"
                            >
                              <v-icon>mdi-dots-horizontal</v-icon>
                            </v-btn>
                          </v-list-item-action>
                        </v-list-item>
                        <v-divider
                          v-if="i < rootfile.length -1"
                          :key="i.title"
                          class="mx-4"
                          style="border-color: #F5F5F5;"
                        ></v-divider>
                      </template>
                    </v-list>
                    <v-list v-else class=" elevation-0" id="borderradius">
                      <v-list-item>
                        <v-list-item-content>
                          <v-img
                            :src="require('../assets/img/icon_empty_data.png')"
                            max-width="132"
                            max-height="150"
                            class="mx-auto"
                          />
                          <v-list-item-title
                            :style="'font-size: 16px; font-weight: 700; line-height: 24px; color:' + color.theme + ';'"
                            class="text-center mt-8"
                            v-text="$t('tablefile.empty')"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </div>
                  <br />
                </v-flex>
              </v-layout>

              <!-- <scroll-loader class="pa-0" :loader-method="fn_get_history" :loader-disable="disablescrolling">
              </scroll-loader> -->
              <div class="text-center" v-if="loaddataprogress === true">
                <v-progress-circular
                  :size="50"
                  :color="color.theme"
                  indeterminate
                ></v-progress-circular>
              </div>
            </v-container>
          </v-card-text>
        </v-card>
      </v-card>
    </v-content>
    <dialogsnackbardowload
      :show="opensnackbardownloadfile"
      @closedialog="opensnackbardownloadfile = false"
      :filedata="file"
      :percen="newpercen"
      :name="namefile"
      :list="new_list"
      :btsdownload="btsdownload"
    ></dialogsnackbardowload>
    <dialogdetailpackegehistory
      :show="opendetailpackegehistory"
      @closedialog="opendetailpackegehistory = false"
      :filedata="filepackage"
      @downloadtaxinvoice="
        fn_download_taxinvoice(filepackage), (opendetailpackegehistory = false)
      "
      @payment="fn_payment(), (opendetailpackegehistory = false)"
    ></dialogdetailpackegehistory>
  </v-app>
</template>
<script>
//Import lib
import Swal from "sweetalert2/dist/sweetalert2.js";
import { mapState, mapGetters } from "vuex";
import InfiniteLoading from "vue-infinite-loading";
import "vue-full-screen-file-drop/dist/vue-full-screen-file-drop.css";
import VueFullScreenFileDrop from "vue-full-screen-file-drop";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import { slider, slideritem } from "vue-concise-slider";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
const onebox_toolbar = () => import("../components/layout/layout-toolbar-new");
const dialogsnackbardowload = () =>
  import("../components/optional/dialog-snackbardownload");
const dialogdetailpackegehistory = () =>
  import("../components/optional/dialog-detailpackegehistory");
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});
export default {
  data: () => ({
    filepackage: {},
    opendetailpackegehistory: false,
    URL_payment: "",
    newlist: [],
    namefile: "",
    file: {},
    percentCompleted: 0,
    newpercen: 0,
    new_list: [],
    btsdownload: false,
    listdatadowload: [],
    opensnackbardownloadfile: false,
    listpayment: [],
    singleSelect: false,
    filemultipledelete: [],
    disablescrolling: false,
    rootfile: [],
    search: "",
    statusmutipledelete: false,
    pagination: {
      rowsPerPage: 100000,
      sortBy: "file_id",
      totalItems: 0,
      rowsPerPageItems: [1, 2, 4, 8],
    },
    loaddataprogress: false,
    page: 1,
    offset: 0,
    count: 0,
    size: 20,
    listsize: [5, 20, 50, 100],
    headers: [
      {
        text: "paymentlist.package_name",
        align: "left",
        value: "package_name",
        width: "18.5%",
        sortable: false,
      },
      {
        text: "packagepurchasehistory.package_name_addon",
        align: "left",
        value: "package_name_addon",
        width: "20%",
        sortable: false,
      },
      {
        text: "paymentlist.price",
        align: "left",
        value: "amount",
        width: "15%",
        sortable: false,
      },
      {
        text: "paymentlist.payment_date",
        value: "pay_type",
        width: "15%",
        align: "left",
        sortable: false,
      },
      {
        text: "paymentlist.dateofpayment",
        value: "paid_dtm",
        width: "15%",
        align: "left",
        sortable: false,
      },
      {
        text: "paymentlist.payment_status",
        value: "status_payment",
        width: "15%",
        align: "center",
        sortable: false,
      },
      {
        text: "paymentlist.tax_invoice",
        value: "file_status",
        width: "115",
        align: "center",
        sortable: false,
      },
      // {
      //   text: "ใบกำกับภาษี",
      //   value: "order_id",
      //   width: "10%",
      //   align: "center",
      //   sortable: false,
      // },
      {
        text: "paymentlist.pay",
        value: "pay",
        width: "10%",
        align: "center",
        sortable: false,
      },
    ],
  }),
  components: {
    InfiniteLoading,
    VueFullScreenFileDrop,
    onebox_toolbar,
    Swiper,
    SwiperSlide,
    dialogsnackbardowload,
    dialogdetailpackegehistory,
  },
  computed: {
    ...mapState([
      "username",
      "authorize",
      "account_active",
      "color",
      "storage_usage",
    ]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataStorageUsage",
      "dataStorageMax",
    ]),
    btnStates() {
      return this.buttons.map((btn) => btn.state);
    },
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    headergb() {
      return "font-size: 40px";
    },
    header() {
      return "font-size: 30px";
    },
    price_() {
      return "color:black;" + "font-weight: 600; font-size:28px;";
    },
    headerTable() {
      return (
        "color:" + this.color.theme + ";" + "font-weight: 600; font-size:15px;"
      );
    },
    headerPage() {
      return (
        "color:" + this.color.theme + ";" + "font-size: 18px; font-weight: 600;"
      );
    },
    colorVapp() {
      return "background:" + this.color.BG + ";";
    },
    colorSort() {
      return "color:" + this.color.alertText + ";";
    },
    colorProgress() {
      return "color:" + this.color.theme + ";";
    },
  },
  methods: {
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    formatdatetime(_datetime) {
      if (
        _datetime === "" ||
        _datetime === "-" ||
        _datetime === undefined ||
        _datetime === null
      ) {
        return "-";
      } else {
        let dateyear =
          _datetime.split("")[0] +
          _datetime.split("")[1] +
          _datetime.split("")[2] +
          _datetime.split("")[3];
        let datemonth = _datetime.split("")[4] + _datetime.split("")[5];
        let dateday = _datetime.split("")[6] + _datetime.split("")[7];
        let hour = _datetime.split("")[8] + _datetime.split("")[9];
        let minute = _datetime.split("")[10] + _datetime.split("")[11];
        let second = _datetime.split("")[12] + _datetime.split("")[13];

        return (
          dateday +
          "/" +
          datemonth +
          "/" +
          dateyear +
          " " +
          hour +
          ":" +
          minute +
          ":" +
          second
        );
      }
    },
    onScroll() {
      const showsize = window.innerWidth;
      this.isFooterDesktop = showsize > 600;
      this.isFooterMobile = showsize < 600;
    },
    fn_get_history() {
      this.rootfile = [];
      let payload = {
        data_id:
          this.dataAccountActive.type === "Citizen"
            ? this.dataAccountId
            : this.dataAccountActive.business_info.business_id,
        type:
          this.dataAccountActive.type === "Citizen" ? "citizen" : "business",
        name_buyer_th: "",
        name_buyer_eng: "",
        order_id: "",
        status_payment: "",
        paid_dtm_start: "",
        paid_dtm_end: "",
      };
      console.log("payload fn_get_history", payload);
      this.axios
        .post(
          process.env.VUE_APP_SERVICE_ADMIN + "/api/payment_list",
          payload
          //  {headers: { Authorization: auth.code } }
        )
        .then((response) => {
          // console.log("res*", response.data.list_payment);
          if (response.data.status === "OK") {
            console.log("response", response);
            this.count = response.data.count;
            for (var i = 0; i < response.data.list_payment.length; i++) {
              let datapatment = {};
              datapatment["amount"] = response.data.list_payment[i].amount;
              datapatment["bank_no"] = response.data.list_payment[i].bank_no;
              datapatment["file_id"] = response.data.list_payment[i].file_id;
              datapatment["name_buyer_eng"] =
                response.data.list_payment[i].name_buyer_eng;
              datapatment["name_buyer_th"] =
                response.data.list_payment[i].name_buyer_th;
              datapatment["order_id"] = response.data.list_payment[i].order_id;
              datapatment["package_name"] =
                response.data.list_payment[i].package_name;
              datapatment["package_name_addon"] =
                response.data.list_payment[i].package_name_addon;
              datapatment["paid_dtm"] = response.data.list_payment[i].paid_dtm;
              datapatment["pay_type"] = response.data.list_payment[i].pay_type;
              datapatment["status_payment"] =
                response.data.list_payment[i].status_payment;
              datapatment["ref1"] = response.data.list_payment[i].ref1;
              datapatment["cre_dtm"] = response.data.list_payment[i].cre_dtm;
              datapatment["data_id"] = response.data.list_payment[i].data_id;
              datapatment["number_user"] =
                response.data.list_payment[i].number_user;
              datapatment["order_payment_id"] =
                response.data.list_payment[i].order_payment_id;
              datapatment["package_id"] =
                response.data.list_payment[i].package_id;
              datapatment["package_name_th"] =
                response.data.list_payment[i].package_name_th;
              datapatment["package_storage"] =
                response.data.list_payment[i].package_storage;
              datapatment["period"] = response.data.list_payment[i].period;
              datapatment["type_package"] =
                response.data.list_payment[i].type_package;
              datapatment["types"] = response.data.list_payment[i].types;
              datapatment["URL_payment"] =
                response.data.list_payment[i].URL_payment;
              datapatment["quantity_addon"] =
                response.data.list_payment[i].quantity_addon;
              datapatment["quantity_main"] =
                response.data.list_payment[i].quantity_main;
              datapatment["package_storage_main"] =
                response.data.list_payment[i].package_storage_main;

              this.rootfile.push(datapatment);
            }

            console.log("this.rootfile", this.rootfile);
          } else {
            Toast.fire({
              icon: "error",
              title: response.data.errorMessage,
            });
          }
        });
    },
    async fn_download(item) {
      console.log("item", item);
      // if (item.status_payment === "Success") {
      this.btsdownload = true;
      let datadowload = {};
      this.newlist = [];
      this.listdatadowload.push(item);
      console.log("====", this.listdatadowload);
      for (let i = 0; i < this.listdatadowload.length; i++) {
        datadowload["fileid"] = this.listdatadowload[i].file_id;
        datadowload["name"] =
          item.status_payment === "Success" ||
          item.status_payment === "success_nexmount"
            ? "ใบกำกับภาษี_" + this.listdatadowload[i].ref1
            : item.status_payment === "wait"
            ? "ใบแจ้งหนี้_" + this.listdatadowload[i].ref1
            : "ใบแจ้งหนี้_" + this.listdatadowload[i].ref1;
        datadowload["value"] = 0;
      }
      this.newlist.push(datadowload);
      console.log("this.newlist", this.newlist);
      // this.percentCompleted = 0;

      let url =
        process.env.VUE_APP_SERVICE_DOWNLOAD_FILE +
        "/api/v2/download_file?user_id=" +
        this.dataUsername +
        "&file_id=" +
        item.file_id;

      for (let i = 0; i < this.listdatadowload.length; i++) {
        if (
          item.status_payment === "Success" ||
          item.status_payment === "success_nexmount"
        ) {
          this.dataIndex = this.newlist.findIndex(
            (obj) => obj.name === "ใบกำกับภาษี_" + this.listdatadowload[i].ref1
          );
        } else if (item.status_payment === "wait") {
          this.dataIndex = this.newlist.findIndex(
            (obj) => obj.name === "ใบแจ้งหนี้_" + this.listdatadowload[i].ref1
          );
        } else {
          this.dataIndex = this.newlist.findIndex(
            (obj) => obj.name === "ใบแจ้งหนี้_" + this.listdatadowload[i].ref1
          );
        }
      }
      // console.log("file", this.dataIndex);
      this.opensnackbardownloadfile = true;
      this.new_list = this.newlist;
      let auth = await gbfGenerate.generateToken();
      this.axios
        .get(url, {
          headers: { Authorization: auth.code },
          onDownloadProgress: (progressEvent) => {
            // console.log("progressEvent", progressEvent);
            let progresspercent = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            console.log("progresspercent", progresspercent);
            // console.log(this.percentCompleted);

            if (this.newlist[this.dataIndex]["value"] === 95) {
              this.newlist[this.dataIndex]["value"] = 95;
            } else {
              this.newlist[this.dataIndex]["value"] = progresspercent;
            }
            // console.log("percenfile", this.newlist[this.dataIndex]["value"]);
            this.newpercen = this.newlist[this.dataIndex]["value"];
            this.namefile = this.newlist[this.dataIndex]["name"];
          },
          withCredentials: false,
          responseType: "arraybuffer",
        })
        .then((response) => {
          // ตรวจสอบว่า  API ทำงานถูกต้อง
          // if (response.statusText === "OK") {
          if (response.status === 200) {
            console.log("response++++++++++++ OK", response);
            // ตรวจสอบว่า Backend ส่ง Error มาหรือไม่
            if (
              parseInt(response.headers["content-length"]) < 700 &&
              (response.headers["content-type"]) === "application/json"
            ) {
              // Backend ไม่ส่งไฟล์มา
              console.log("response < 1000", response);
              this.opensnackbardownloadfile = false;
              let res_error = gbfdecodearraybuffer.decodearraybuffer(
                response.data
              );
              console.log("responseerror", res_error);
              Toast.fire({
                icon: "error",
                title: res_error.errorMessage,
              });
            } else {
              this.opensnackbardownloadfile = true;
              this.newlist[this.dataIndex]["value"] = 100;
              this.btsdownload = false;

              let headers = response.headers;
              let blob = new Blob([response.data], {
                type: headers["content-type"],
              });
              let link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.download =
                item.status_payment === "Success" ||
                item.status_payment === "success_nexmount"
                  ? "ใบกำกับภาษี_" + item.ref1
                  : item.status_payment === "wait"
                  ? "ใบแจ้งหนี้_" + item.ref1
                  : "ใบแจ้งหนี้_" + item.ref1;
              link.click();
              setTimeout(() => {
                this.opensnackbardownloadfile = false;
              }, 2500);
            }
          } else {
            this.opensnackbardownloadfile = false;
            Toast.fire({
              icon: "error",
              title: this.$t("toast.cannotconnectonebox"),
            });
          }
          this.listdatadowload = []; //เคลียร์listที่เลือกดาวน์โหลด
          this.newlist = []; //เคลียร์ array เป็น key แล้ว
          this.newpercen = []; //เคลียร์ค่าเปอร์เซ็น
        })
        .catch((error) => {
          this.btsdownload = false;
          // this.opensnackbar = false;
          this.opensnackbardownloadfile = false;
          Toast.fire({
            icon: "error",
            title: "ไม่สามารถดาวน์โหลดนี้ได้",
          });
          console.log("err", error);
        });
      // } else {
      //   this.btsdownload = true;
      //   this.opensnackbardownloadfile = true;
      //   let timeout = checkfilesize * 4000;
      //   window.location.assign(url);
      //   setTimeout(() => {
      //     this.opensnackbardownloadfile = false;
      //   }, timeout);
      // }
      // } else {
      //   Toast.fire({
      //     icon: "error",
      //     title: "ไม่สามารถดาวน์โหลดใบกำกับภาษีนี้ได้",
      //   });
      // }
    },
    fn_backtomainpage() {
      if (this.dataAccountActive.type === "Business") {
        this.$router.replace({ path: "/packagenew" });
      } else {
        this.$router.replace({ path: "/packagecurrent" });
      }
    },
    fn_payment(data) {
      // console.log("data ---",data);
      this.URL_payment = data.URL_payment;
      window.open(this.URL_payment, "_self");
    },
  },
  mounted() {
    this.fn_get_history();
  },
};
</script>
<style>
#thiscontainer_packagpurchasehistory {
  height: calc(101vh - 180px);
  /* height: calc(var(--vh, 1vh) * 100); */
  overflow-x: hidden;
  /* max-width: 100%; */
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
}
#borderradius {
  border-radius: 15px;
}
#borderradius_card {
  border-radius: 26px;
}
</style>
